<template>
    <div v-if="visible" class="modal-overlay" @click.self="close">
        <div class="modal-content">
            <button class="close-button" @click="close">✖</button>
            <h1 v-if="isRegistering">Register</h1>
            <h1 v-else>Login</h1>
            <form @submit.prevent="handleSubmit">
                <input type="email" v-model="email" placeholder="Email" required />
                <input type="password" v-model="password" placeholder="Password" required />
                <div v-if="isRegistering">
                    <input type="text" v-model="username" placeholder="Username" required />
                </div>
                <button type="submit" :disabled="loading">
                    <span v-if="loading">Loading...</span>
                    <span v-else>{{ isRegistering ? 'Register' : 'Login' }}</span>
                </button>
            </form>
            <div class="toggle-container">
                <div class="toggle-text">
                    {{ isRegistering ? 'Already have an account?' : 'Don\'t have an account?' }}
                </div>
                <button class="toggle-button" @click="toggleRegistering" :disabled="loading">
                    {{ isRegistering ? 'Login' : 'Register' }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '@/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            email: '',
            password: '',
            username: '',
            isRegistering: false,
            loading: false,
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async handleSubmit() {
            this.loading = true;
            try {
                if (this.isRegistering) {
                    await this.registerWithEmail();
                } else {
                    await this.WithEmail();
                }
            } catch (error) {
                console.error('Error during form submission', error);
                alert(`Error: ${error.message}`);
            } finally {
                this.loading = false;
            }
        },
        async registerWithEmail() {
            const usernameAvailable = await this.checkUsernameAvailability();
            if (!usernameAvailable) {
                alert('Username is already taken');
                throw new Error('Username is already taken');
            }
            const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
            const userId = userCredential.user.uid;

            await setDoc(doc(db, 'users', userId), {
                username: this.username,
                profileImage: '',
            });
            await setDoc(doc(db, 'usernames', this.username), {
                userId: userId,
            });

            localStorage.setItem('user', JSON.stringify(userCredential.user));
            this.$router.push('/');
            this.close();
        },
        async loginWithEmail() {
            const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
            localStorage.setItem('user', JSON.stringify(userCredential.user));
            this.$router.push('/');
            this.close();
        },
        async checkUsernameAvailability() {
            const docRef = doc(db, 'usernames', this.username);
            const docSnap = await getDoc(docRef);
            return !docSnap.exists();
        },
        toggleRegistering() {
            this.isRegistering = !this.isRegistering;
        },
    },
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 90%; /* Ensure the modal width is responsive */
    max-width: 400px; /* Set a maximum width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevent overflow issues */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Ensure the input fields take the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    margin-bottom: 10px;
    width: 100%; /* Ensure the button takes the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

button:disabled {
    background-color: #ccc;
}

.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.toggle-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.toggle-text {
    font-size: 14px;
}

</style>
