import './assets/main.css'

import { createPinia } from 'pinia'
import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase';

const app = createApp(App)

// Create a reactive state for the profile image
const profileImageState = reactive({ profileImageUrl: 'https://via.placeholder.com/40' })

// Load user profile image on app initialization
const loadUserProfileImage = async () => {
    const user = localStorage.getItem('user')
    if (user) {
        const parsedUser = JSON.parse(user)
        if (parsedUser && parsedUser.uid) {
            const userId = parsedUser.uid
            const docRef = doc(db, 'users', userId)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                profileImageState.profileImageUrl = docSnap.data().profileImage || 'https://via.placeholder.com/40'
            }
        }
    }
}

loadUserProfileImage()

app.provide('profileImageState', profileImageState)

app.use(router)
app.use(createPinia())

app.mount('#app')
