<template>
    <div class="reply">
        <div class="reply-header">
            <img :src="userProfileImage" alt="User Image" class="user-image" />
            <div class="text-container">
                <p class="reply-title">{{ reply.title }}</p>
                <p class="reply-username">{{ userName }}</p>
            </div>
        </div>
        <div class="reply-content">
            <AudioPlayer :audioURL="reply.audioURL" />
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';

export default {
    components: {
        AudioPlayer,
    },
    props: {
        reply: {
            type: Object,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        postId: {
            type: String,
            required: true,
        },
        commentId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            userName: 'Loading...',
            userProfileImage: 'https://via.placeholder.com/40',
        };
    },
    async mounted() {
        await this.fetchUserProfile(this.reply.userId);
    },
    methods: {
        async fetchUserProfile(userId) {
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                this.userName = userData.username || 'Anonymous';
                this.userProfileImage = userData.profileImage || 'https://via.placeholder.com/40';
            } else {
                console.error('No user data found!');
            }
        },
    },
};
</script>

<style scoped>
.reply {
    position: relative;
    margin-top: 8px;
}

.reply-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.reply-header img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reply-title {
    font-size: 14px;
    margin: 0;
}

.reply-username {
    font-size: 10px;
    color: #888;
    margin: 0;
}

.reply-content {
    margin-top: 4px;
}
</style>
