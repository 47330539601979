<template>
    <div id="app">
        <Navbar 
            :isLoggedIn="isLoggedIn" 
            :profileImage="profileImage" 
            @show-auth-modal="toggleModal" 
            @logout="handleLogout"
        />
        <div class="main-content">
            <SideNav class="side-nav" />
            <div class="page-content">
                <AuthModal :visible="isModalVisible" @close="toggleModal" />
                <router-view />
            </div>
        </div>
    </div>
</template>


<script>
import Navbar from '@/components/Navbar.vue';
import AuthModal from '@/components/AuthModal.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase';
import SideNav from './components/SideNav.vue';
import PostSeries from './components/PostSeries.vue';
import AudioPost from './components/AudioPost.vue';

export default {
    components: {
        Navbar,
        AuthModal,
        SideNav,
        PostSeries
    },
    data() {
        return {
            isModalVisible: false,
            isLoggedIn: false,
            profileImage: 'https://via.placeholder.com/40',
        };
    },
    methods: {
        toggleModal() {
            this.isModalVisible = !this.isModalVisible;
        },
        handleLogout() {
            this.isLoggedIn = false;
            this.profileImage = 'https://via.placeholder.com/40';
        },
    },
    mounted() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.isLoggedIn = true;
                // Assuming user.photoURL contains the user's profile image URL
                this.profileImage = user.photoURL || 'https://via.placeholder.com/40';
            } else {
                this.isLoggedIn = false;
            }
        });
    },
};
</script>

<style scoped>
#app {
    display: flex;
    flex-direction: column;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white; /* Ensure background color is set to avoid overlap issues */
    border-bottom: 1px solid grey; /* Add border to differentiate the navbar from the content */
}

.main-content {
    display: flex;
    flex: 1;
    margin-top: 60px; /* Adjust this value to match the height of your navbar */
}

.page-content {
    flex: 1;
    padding: 20px;
}

/* Adjust layout for small screens */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
    }

    .side-nav {
        display: none; /* Hide the sidebar on small screens */
    }

    .page-content {
        margin-left: 0;
        padding: 10px;
    }
}

/* Adjust layout for medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
    .main-content {
        flex-direction: row;
    }

    .side-nav {
        width: 100px; /* Adjust sidebar width */
    }

    .page-content {
        margin-left: 135px; /* Adjust margin to match sidebar width */
    }
}

/* Default layout for large screens */
@media (min-width: 1025px) {
    .side-nav {
        width: 150px; /* Adjust sidebar width */
    }

    .page-content {
        margin-left: 200px; /* Adjust margin to match sidebar width */
    }
}
</style>