<template>
    <div class="post-series">
        <AudioPost
            v-for="post in posts"
            :key="post.id"
            :post="post"
            :isLoggedIn="isLoggedIn"
            @show-auth-modal="$emit('show-auth-modal')"
        />
    </div>
</template>

<script>
import AudioPost from '@/components/AudioPost.vue';

export default {
    components: {
        AudioPost,
    },
    props: {
        posts: {
            type: Array,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped>
.post-series {
    display: flex;
    flex-direction: column;
}
</style>
