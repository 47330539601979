import Home from '@/views/Home.vue';
import { createRouter, createWebHistory } from 'vue-router';
import ProfilePage from '@/views/ProfilePage.vue';
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfilePage
    }
];
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});
// router.beforeEach((to, from, next) => {
//     const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//     const isAuthenticated = auth.currentUser;
//     if (requiresAuth && !isAuthenticated) {
//         next('/login');
//     } else {
//         next();
//     }
// });
export default router;
