<template>
    <div class="audio-recorder">
        <h2>{{ isReply ? 'Record Reply' : (isComment ? 'Record Comment' : 'Record New Post') }}</h2>
        <input type="text" v-model="title" placeholder="Title (max 35 characters)" maxlength="35" />
        <canvas ref="waveform" id="waveform"></canvas>
        <button @click="startRecording" :disabled="isRecording">Start Recording</button>
        <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
        <button @click="uploadAudio" :disabled="!audioBlob || !title || isUploading">Upload</button>

        <div v-if="audioURL" class="custom-audio-player">
            <button @click="playOrPause" :disabled="isLoading">
                <span v-if="isLoading">Loading...</span>
                <span v-else>{{ isPlaying ? 'Pause' : 'Play' }}</span>
            </button>
        </div>

        <div v-if="isUploading" class="spinner">Uploading...</div>
    </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, db } from '@/firebase';
import { doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore';

export default {
    props: {
        isComment: {
            type: Boolean,
            default: false,
        },
        isReply: {
            type: Boolean,
            default: false,
        },
        postId: {
            type: String,
            default: null,
        },
        commentId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            mediaRecorder: null,
            audioChunks: [],
            audioBlob: null,
            audioURL: '',
            isRecording: false,
            isPlaying: false,
            isLoading: false,
            title: '',
            audioInstance: null,
            isUploading: false,
            canvas: null,
            canvasCtx: null,
            animationId: null,
            completeDataArray: [],
        };
    },
    methods: {
        async playOrPause() {
            if (this.isPlaying) {
                this.audioInstance.pause();
                this.isPlaying = false;
                return;
            }
            if (!this.audioInstance) {
                this.isLoading = true;
                try {
                    this.audioInstance = new Audio();
                    this.audioInstance.src = this.audioURL;
                    this.audioInstance.preload = "auto";
                    await this.audioInstance.load();

                    this.audioInstance.onended = () => {
                        this.isPlaying = false;
                    };

                    this.isLoading = false;
                    await this.audioInstance.play();
                    this.isPlaying = true;
                } catch (error) {
                    console.error('Playback failed:', error);
                    alert('Unable to play audio. Please try again.');
                    this.isLoading = false;
                }
            } else {
                await this.audioInstance.play();
                this.isPlaying = true;
            }
        },
        getSupportedMimeType() {
            const possibleTypes = [
                'audio/webm;codecs=opus',
                'audio/mp4',
                'audio/mpeg',
                'audio/ogg;codecs=opus',
                'audio/wav',
            ];

            for (const type of possibleTypes) {
                if (MediaRecorder.isTypeSupported(type)) {
                    return type;
                }
            }

            // Default fallback if none are supported
            return '';
        },

        async startRecording() {
            try {
                this.audioChunks = [];
                this.completeDataArray = [];

                const mimeType = this.getSupportedMimeType();
                if (!mimeType) {
                    alert('Your browser does not support any of the required audio formats.');
                    return;
                }

                // Get the user's audio input
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                // Create a new audio context
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

                // Create an analyser node
                this.analyser = this.audioContext.createAnalyser();
                this.analyser.fftSize = 2048;
                this.bufferLength = this.analyser.frequencyBinCount;
                this.dataArray = new Uint8Array(this.bufferLength);

                // Create a media stream source from the audio input
                const source = this.audioContext.createMediaStreamSource(stream);

                // Connect the source to the analyser
                source.connect(this.analyser);

                // Set up the media recorder
                this.mediaRecorder = new MediaRecorder(stream, { mimeType });
                this.mediaRecorder.start();
                this.isRecording = true;

                // Capture audio data
                this.mediaRecorder.addEventListener('dataavailable', event => {
                    this.audioChunks.push(event.data);
                });

                // Handle the stop event of the recorder
                this.mediaRecorder.addEventListener('stop', () => {
                    this.audioBlob = new Blob(this.audioChunks, { type: mimeType });
                    this.audioURL = URL.createObjectURL(this.audioBlob);
                    cancelAnimationFrame(this.animationId);
                    this.drawCompleteWaveform();
                });

                // Start capturing audio data for visualization
                this.captureAudioData();
            } catch (error) {
                console.error('Error during recording:', error);
                alert('Unable to start recording. Please try again.');
            }
        },

        captureAudioData() {
            const capture = () => {
                if (!this.analyser) {
                    return; // Ensure the analyser is defined before accessing it
                }

                this.analyser.getByteTimeDomainData(this.dataArray);
                this.completeDataArray.push(...this.dataArray);
                this.drawLiveWaveform();

                if (this.isRecording) {
                    this.animationId = requestAnimationFrame(capture);
                }
            };
            capture();
        },
        async stopRecording() {
            this.isRecording = false;
            this.mediaRecorder.stop();
        },
        async uploadAudio() {
            if (!this.audioBlob || this.isUploading) return; // Prevent multiple uploads

            this.$emit('uploading', true);  // Start uploading
            this.isUploading = true;

            const auth = getAuth();
            const user = auth.currentUser;
            if (!user || !user.uid) {
                alert('You must be logged in to upload audio.');
                this.$emit('uploading', false);  // End uploading
                this.isUploading = false;
                return;
            }

            const storageRef = ref(storage, `audio/${user.uid}/${Date.now()}.webm`);
            try {
                const snapshot = await uploadBytes(storageRef, this.audioBlob);
                const downloadURL = await getDownloadURL(snapshot.ref);

                const uploadData = {
                    userId: user.uid,
                    title: this.title,
                    audioURL: downloadURL,
                    postId: this.postId,
                    commentId: this.commentId,
                    timestamp: serverTimestamp(),
                };

                let collectionName;
                if (this.isReply) {
                    collectionName = `posts/${this.postId}/comments/${this.commentId}/replies`;
                } else if (this.isComment) {
                    collectionName = `posts/${this.postId}/comments`;
                } else {
                    collectionName = 'posts';
                }
                await addDoc(collection(db, collectionName), uploadData);

                this.$emit('upload', uploadData);
                this.$emit('uploading', false);  // End uploading
            } catch (error) {
                console.error('Error uploading audio', error);
                alert(`Error: ${error.message}`);
            }
        },
        async fetchUserProfile(userId) {
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                return userData;
            } else {
                return { userName: 'Anonymous', profileImage: 'https://via.placeholder.com/40' };
            }
        },
        drawLiveWaveform() {
            this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            this.canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.lineWidth = 2;
            this.canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
            this.canvasCtx.beginPath();
            const sliceWidth = this.canvas.width * 1.0 / this.bufferLength;
            let x = 0;
            for (let i = 0; i < this.bufferLength; i++) {
                const v = this.dataArray[i] / 128.0;
                const y = v * this.canvas.height / 2;
                if (i === 0) {
                    this.canvasCtx.moveTo(x, y);
                } else {
                    this.canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            this.canvasCtx.lineTo(this.canvas.width, this.canvas.height / 2);
            this.canvasCtx.stroke();
        },
        normalizeData(data) {
            const maxPoints = 1000; // Maximum number of points to display
            if (data.length > maxPoints) {
                const downsampledData = [];
                const step = Math.ceil(data.length / maxPoints);
                for (let i = 0; i < data.length; i += step) {
                    downsampledData.push(data[i]);
                }
                data = downsampledData;
            }

            const max = Math.max(...data);
            if (max === 0) return data; // Prevent division by zero
            return data.map(n => n / max);
        },
        drawCompleteWaveform() {
            if (this.completeDataArray.length === 0) return;

            const normalizedData = this.normalizeData(this.completeDataArray);
            const sliceWidth = this.canvas.width * 1.0 / normalizedData.length;

            this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.fillStyle = 'rgb(200, 200, 200)';
            this.canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.canvasCtx.lineWidth = 2;
            this.canvasCtx.strokeStyle = 'rgb(0, 0, 0)';
            this.canvasCtx.beginPath();

            let x = 0;
            for (let i = 0; i < normalizedData.length; i++) {
                const v = normalizedData[i];
                const y = v * this.canvas.height / 2;
                if (i === 0) {
                    this.canvasCtx.moveTo(x, y);
                } else {
                    this.canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            this.canvasCtx.lineTo(this.canvas.width, this.canvas.height / 2);
            this.canvasCtx.stroke();
        },
    },
    mounted() {
        this.canvas = this.$refs.waveform;
        this.canvasCtx = this.canvas.getContext('2d');
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = 200;
    },
    beforeDestroy() {
        cancelAnimationFrame(this.animationId);
        if (this.audioInstance) {
            this.audioInstance.pause();
            this.audioInstance = null;
        }
    },
};
</script>

<style scoped>
.audio-recorder {
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 8px;
}

.audio-recorder input[type="text"] {
    margin-bottom: 8px;
    width: 97%;
    padding: 4px;
}

.audio-recorder canvas {
    margin: 8px auto;
    width: 98%;
    height: 100px;
}

.audio-recorder button {
    margin: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background-color: #352eff;
    color: white;
    transition: background-color 0.3s ease;
}

.audio-recorder button:hover {
    background-color: #45a049;
}

.audio-recorder button:disabled {
    background-color: #a5a5a5;
    cursor: not-allowed;
}

.audio-recorder .audio-player {
    margin-top: 8px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.custom-audio-player {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.custom-audio-player button {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background-color: #4CAF50;
    color: white;
    margin-right: 10px;
}

.progress-bar {
    flex: 1;
    height: 10px;
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.progress {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 5px;
    width: 0%;
}
</style>
