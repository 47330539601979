<template>
    <div class="audio-post">
        <div class="post-header">
            <img :src="userProfileImage" alt="User Image" class="user-image" />
            <div class="text-container">
                <p class="post-title">{{ post.title }}</p>
                <p class="post-username">{{ userName }}</p>
            </div>
        </div>

        <AudioPlayer :audioURL="post.audioURL" />

        <div class="post-actions">
            <button v-if="isLoggedIn" @click="showCommentRecorder">Comment</button>
            <button v-else @click="showAuthModal">Login to Comment</button>
        </div>

        <audio-recorder v-if="showRecorder" @upload="handleCommentUpload" :is-comment="true" :post-id="post.id" />

        <div class="comments">
            <Comment v-for="comment in post.comments" :key="comment.id" :comment="comment" :isLoggedIn="isLoggedIn"
                :post-id="post.id" @show-auth-modal="showAuthModal" />
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';
import AudioRecorder from '@/components/AudioRecorder.vue';
import Comment from '@/components/Comment.vue';
import { db } from '@/firebase';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';

export default {
    components: {
        AudioPlayer,
        AudioRecorder,
        Comment,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            userName: 'Loading...',
            userProfileImage: 'https://via.placeholder.com/40',
            showRecorder: false,
        };
    },
    methods: {
        showCommentRecorder() {
            this.showRecorder = true;
        },
        showAuthModal() {
            this.$emit('show-auth-modal');
        },
        async fetchUserProfile(userId) {
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                this.userName = userData.username || 'Anonymous';
                this.userProfileImage = userData.profileImage || 'https://via.placeholder.com/40';
            } else {
                console.error('No user data found!');
            }
        },
        async fetchComments() {
            try {
                const commentsCollection = collection(db, `posts/${this.post.id}/comments`);
                const commentsSnapshot = await getDocs(commentsCollection);
                const comments = await Promise.all(
                    commentsSnapshot.docs.map(async (commentDoc) => {
                        const commentData = commentDoc.data();
                        const repliesCollection = collection(
                            db,
                            `posts/${this.post.id}/comments/${commentDoc.id}/replies`
                        );
                        const repliesSnapshot = await getDocs(repliesCollection);
                        const replies = repliesSnapshot.docs.map((replyDoc) => ({
                            id: replyDoc.id,
                            ...replyDoc.data(),
                        }));

                        return {
                            id: commentDoc.id,
                            ...commentData,
                            replies,
                        };
                    })
                );
                this.post.comments = comments;
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        },
        async handleCommentUpload() {
            await this.fetchComments();
            this.showRecorder = false;
        },
    },
    async mounted() {
        await this.fetchUserProfile(this.post.userId);
        await this.fetchComments();
    },
};
</script>

<style scoped>
.audio-post {
    padding: 16px;
    padding-bottom: 32px;
    border-radius: 8px;
    background-color: #fff;
}

.post-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.post-header img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
}

.post-header .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-title {
    font-size: 18px;
    margin: 0;
}

.post-username {
    font-size: 12px;
    color: #888;
    margin: 0;
}

.post-actions {
    margin-top: 10px;
}

.post-actions button {
    margin-right: 10px;
}
</style>
