<template>
    <nav class="navbar">
        <div class="navbar-content">
            <router-link to="/" class="navbar-logo">
                <img src="@/assets/hummingbird.svg" alt="Logo" />
                <span class="navbar-title">VIBBLY</span>
            </router-link>
            <div class="navbar-right">
                <button v-if="!isLoggedIn" @click="$emit('show-auth-modal')">Login</button>
                <div v-else class="profile-dropdown">
                    <img ref="profileImage" :src="profileImageState.profileImageUrl" alt="Profile" class="profile-image" @click="toggleDropdown" />
                    <div v-if="dropdownVisible" class="dropdown-menu" ref="dropdownMenu">
                        <router-link to="/profile">Profile</router-link>
                        <button @click="logout">Logout</button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { signOut } from 'firebase/auth'
import { auth } from '@/firebase'
import { inject } from 'vue'

export default {
    props: {
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const profileImageState = inject('profileImageState')
        return { profileImageState }
    },
    data() {
        return {
            dropdownVisible: false,
        }
    },
    methods: {
        async logout() {
            await signOut(auth)
            localStorage.removeItem('user')
            this.$emit('logout')
            this.$router.push('/')
        },
        toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible
        },
        handleClickOutside(event) {
            if (this.dropdownVisible && this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target) && !this.$refs.profileImage.contains(event.target)) {
                this.dropdownVisible = false;
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
}
</script>

<style scoped>
.navbar {
    background-color: white;
    border-bottom: 1px solid grey;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 10px;
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-logo img {
    height: 40px;
}

@font-face {
    font-family: 'CuteChalk';
    src: url('@/assets/CuteChalk.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.navbar-title {
    font-family: 'CuteChalk', cursive;
    font-size: 24px; 
    font-weight: bold;
    margin-left: 10px; 
    color: black;
    vertical-align: middle;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.profile-image {
    height: 40px;
    width: 40px;
    margin-right: 20px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.profile-dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 50px;
    left: -110px;
    background: white;
    border: 1px solid grey;
    border-radius: 8px;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 130px;
    z-index: 1000;
}

.dropdown-menu button {
    margin-top: 10px;
}
</style>


<!-- Include the Google Fonts link in your index.html or main entry file -->
<link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet">
