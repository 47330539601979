<template>
    <div class="audio-controls">
        <button @click="togglePlay" class="play-pause-button">
            <svg v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                width="24px" height="24px">
                <path d="M8 5v14l11-7z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px"
                height="24px">
                <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
            </svg>
        </button>
        <div ref="waveformContainer" class="waveform-container"></div>
    </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js';

export default {
    props: {
        audioURL: {
            type: String,
            required: true,
        },
        waveColor: {
            type: String,
            default: '#4477CC',
        },
        progressColor: {
            type: String,
            default: '#4477CC',
        },
        height: {
            type: Number,
            default: 50,
        }
    },
    data() {
        return {
            wavesurfer: null,
            isPlaying: false,
            currentTime: 0,
        };
    },
    computed: {
        formattedCurrentTime() {
            const minutes = Math.floor(this.currentTime / 60);
            const seconds = Math.floor(this.currentTime % 60);
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        },
    },
    methods: {
        async togglePlay() {
            if (this.wavesurfer) {
                this.wavesurfer.playPause();
                this.isPlaying = this.wavesurfer.isPlaying();
            }
        },
        updateCurrentTime() {
            if (this.wavesurfer) {
                this.currentTime = this.wavesurfer.getCurrentTime();
            }
        },
    },
    mounted() {
        this.wavesurfer = WaveSurfer.create({
            container: this.$refs.waveformContainer,
            waveColor: this.waveColor,
            progressColor: this.progressColor,
            height: this.height,
            plugins: [
                Timeline.create({
                    container: this.$refs.waveformContainer,
                }),
            ],
        });

        this.wavesurfer.load(this.audioURL);

        this.wavesurfer.on('ready', () => {
            this.isPlaying = false;
        });

        this.wavesurfer.on('audioprocess', this.updateCurrentTime);
        this.wavesurfer.on('finish', () => {
            this.isPlaying = false;
        });
    },
    beforeDestroy() {
        if (this.wavesurfer) {
            this.wavesurfer.destroy();
        }
    },
};
</script>

<style scoped>
.audio-controls {
    display: flex;
    align-items: center;
    width: 100%;
}

.waveform-container {
    flex-grow: 1;
    min-width: 0;
    height: 50px;
}

.play-pause-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.play-pause-button:hover {
    background-color: #45a049;
}

.play-pause-button svg {
    width: 24px;
    height: 24px;
}
</style>
