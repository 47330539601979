<template>
    <div class="comment">
        <div class="comment-header">
            <img :src="userProfileImage" alt="User Image" class="user-image" />
            <div class="text-container">
                <p class="comment-title">{{ comment.title }}</p>
                <p class="comment-username">{{ userName }}</p>
            </div>
        </div>

        <div class="comment-content">
            <AudioPlayer :audioURL="comment.audioURL" />
        </div>

        <div class="comment-actions">
            <button class="comment-button" v-if="isLoggedIn" @click="showReplyRecorder">Reply</button>
            <button class="comment-button" v-else @click="showAuthModal">Login to Reply</button>
        </div>

        <audio-recorder v-if="showRecorder" @upload="handleReplyUpload" :is-reply="true" :post-id="postId"
            :comment-id="comment.id" />

        <div class="replies">
            <Reply v-for="reply in comment.replies" :key="reply.id" :reply="reply" :isLoggedIn="isLoggedIn"
                :post-id="postId" :comment-id="comment.id" @show-auth-modal="showAuthModal" />
        </div>
    </div>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';
import AudioRecorder from '@/components/AudioRecorder.vue';
import Reply from '@/components/Reply.vue';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';

export default {
    components: {
        AudioPlayer,
        AudioRecorder,
        Reply,
    },
    props: {
        comment: {
            type: Object,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        postId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showRecorder: false,
            userName: 'Loading...',
            userProfileImage: 'https://via.placeholder.com/40',
        };
    },
    methods: {
        async showReplyRecorder() {
            this.showRecorder = true;
        },
        showAuthModal() {
            this.$emit('show-auth-modal');
        },
        async handleReplyUpload(data) {
            this.showRecorder = false;
            const updatedComment = { ...this.comment };
            if (!updatedComment.replies) updatedComment.replies = [];
            updatedComment.replies.push(data);
            this.$emit('update:comment', updatedComment);
        },
        async fetchUserProfile(userId) {
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                this.userName = userData.username || 'Anonymous';
                this.userProfileImage = userData.profileImage || 'https://via.placeholder.com/40';
            } else {
                console.error('No user data found!');
            }
        },
    },
    async mounted() {
        await this.fetchUserProfile(this.comment.userId);
    },
};
</script>

<style scoped>
.comment {
    position: relative;
    margin-top: 8px;
    margin-left: 55px;
}

.comment-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.comment-header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.comment-title {
    font-size: 14px;
    margin: 0;
}

.comment-username {
    font-size: 10px;
    color: #888;
    margin: 0;
}

.comment-content {
    margin-top: 4px;
}

.comment-actions {
    margin-top: 4px;
}

.replies {
    margin-left: 20px;
    padding-left: 16px;
    margin-top: 8px;
    position: relative;
}
</style>
