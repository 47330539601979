import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAHM-H7ucZ0nxSARJkw_QHvyabTteQ9HCU",
    authDomain: "vibbly-796e0.firebaseapp.com",
    projectId: "vibbly-796e0",
    storageBucket: "vibbly-796e0.appspot.com",
    messagingSenderId: "450255351938",
    appId: "1:450255351938:web:46f6fdadee0138ba21049b",
    measurementId: "G-VDJHZ5GRS0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, provider, db, storage };