<template>
    <div v-if="loading" class="loading-indicator">Loading...</div>
    <div v-else>
        <audio-recorder v-if="isLoggedIn" @upload="handlePostUpload" />
        <PostSeries :posts="posts" :isLoggedIn="isLoggedIn" @show-auth-modal="toggleModal" @post-comment="fetchPosts" />
    </div>
</template>

<script>
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, orderBy, query, doc, getDoc } from 'firebase/firestore';
import { auth, db } from '@/firebase';
import AudioRecorder from '@/components/AudioRecorder.vue';
import PostSeries from '@/components/PostSeries.vue';

export default {
    components: {
        AudioRecorder,
        PostSeries,
    },
    data() {
        return {
            isLoggedIn: false,
            posts: [],
            isModalVisible: false,
            loading: true,
        };
    },
    methods: {
        async handlePostUpload() {
            this.loading = true;
            await this.fetchPosts();
            this.loading = false;
        },
        async handleCommentUpload(postId) {
            this.loading = true;
            const post = this.posts.find(p => p.id === postId);
            if (post) {
                await post.fetchComments();
            }
            this.loading = false;
        },
        toggleModal() {
            this.isModalVisible = !this.isModalVisible;
        },
        checkAuthState() {
            onAuthStateChanged(auth, (user) => {
                this.isLoggedIn = !!user;
            });
        },
        async fetchPosts() {
            try {
                const postsCollection = collection(db, 'posts');
                const postsQuery = query(postsCollection, orderBy('timestamp', 'desc'));
                const querySnapshot = await getDocs(postsQuery);

                this.posts = await Promise.all(querySnapshot.docs.map(async (postDoc) => {
                    const postData = postDoc.data();
                    const userDocRef = doc(db, 'users', postData.userId);
                    const userDoc = await getDoc(userDocRef);
                    const userData = userDoc.data();

                    const commentsCollection = collection(db, `posts/${postDoc.id}/comments`);
                    const commentsSnapshot = await getDocs(commentsCollection);
                    const comments = await Promise.all(commentsSnapshot.docs.map(async (commentDoc) => {
                        const commentData = commentDoc.data();
                        const repliesCollection = collection(db, `posts/${postDoc.id}/comments/${commentDoc.id}/replies`);
                        const repliesSnapshot = await getDocs(repliesCollection);
                        const replies = repliesSnapshot.docs.map(replyDoc => ({ id: replyDoc.id, ...replyDoc.data() }));

                        return {
                            id: commentDoc.id,
                            ...commentData,
                            replies,
                        };
                    }));

                    return {
                        id: postDoc.id,
                        comments,
                        userProfileImage: userData.profileImage || 'https://via.placeholder.com/40',
                        userName: userData.username || 'Unknown User',
                        ...postData,
                    };
                }));

                this.loading = false;
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
    },
    mounted() {
        this.checkAuthState();
        this.fetchPosts();
    },
};
</script>

<style scoped>
.loading-indicator {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    font-weight: bold;
}

.main-content {
    display: flex;
    flex: 1;
    margin-left: 100px;
    /* Adjusted to match the new side nav width */
    padding: 20px;
}

.page-content {
    flex: 1;
    padding: 20px;
    margin-right: 20px;
    /* Added margin to the right side */
}

.audio-recorder {
    margin-bottom: 20px;
    /* Add some margin at the bottom to separate it from the posts */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
}

/* Adjust layout for small screens */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        margin-left: 0;
        /* Remove left margin for small screens */
    }

    .side-nav {
        display: none;
        /* Hide the sidebar on small screens */
    }

    .page-content {
        margin-left: 0;
        padding: 10px;
    }
}

/* Adjust layout for medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
    .main-content {
        flex-direction: row;
    }

    .side-nav {
        width: 180px;
        /* Adjust sidebar width */
    }

    .page-content {
        margin-left: 100px;
        /* Adjust margin to match sidebar width */
        padding: 20px;
        /* Ensure padding for page content */
    }
}

/* Default layout for large screens */
@media (min-width: 1025px) {
    .side-nav {
        width: 80px;
        /* Adjust sidebar width */
    }

    .page-content {
        margin-left: 100px;
        /* Adjust margin to match sidebar width */
        padding: 20px;
        /* Ensure padding for page content */
    }
}
</style>
