<template>
    <div class="profile-container">
        <h1>Profile</h1>
        <div class="profile-image-container">
            <img v-if="!isUploading" :src="profileImageUrl || 'https://via.placeholder.com/150'" alt="Profile Image"
                class="profile-image" />
            <div v-else class="spinner"></div>
            <label for="profileImageInput" class="camera-icon">
                <input type="file" id="profileImageInput" @change="handleFileUpload" />
                <div class="icon-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path
                            d="M149.1 64.8L138.7 96 64 96C28.7 96 0 124.7 0 160L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64l-74.7 0L362.9 64.8C356.4 45.2 338.1 32 317.4 32L194.6 32c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
                    </svg>
                </div>
            </label>
        </div>
        <div class="username-container">
            <span v-if="!isEditingUsername"><b>Username:</b> {{ username }}</span>
            <div v-else>
                <input type="text" v-model="username" placeholder="Username" />
                <button @click="saveUsername">Save</button>
                <button @click="cancelEdit">Cancel</button>
            </div>
            <div v-if="!isEditingUsername" class="icon-circle edit-icon" @click="isEditingUsername = true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ref, inject } from 'vue';
import { User } from '@/types/User';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '@/firebase';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { getUserImage, setUserImage } from '@/utils/UserImageCache';

export default defineComponent({
    data() {
        return {
            username: '',
            profileImage: null as File | null,
            profileImageUrl: '',
            userId: null as string | null,
            isEditingUsername: false,
            isUploading: false,
            originalUsername: '',
        };
    },
    async mounted() {
        const user = localStorage.getItem('user');
        if (user) {
            const userData: User = JSON.parse(user);
            if (userData && userData.uid) {
                this.userId = userData.uid;
                const docRef = doc(db, 'users', this.userId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.username = docSnap.data().username;
                    this.originalUsername = this.username;
                    this.profileImageUrl = docSnap.data().profileImage || 'https://via.placeholder.com/150';
                }
            }
        }
    },
    setup() {
        const profileImageState = inject('profileImageState') as any;
        return { profileImageState };
    },
    methods: {
        handleFileUpload(event: Event) {
            const target = event.target as HTMLInputElement;
            this.profileImage = target.files ? target.files[0] : null;
            this.uploadProfileImage();
        },
        async uploadProfileImage() {
            if (this.profileImage && this.userId) {
                this.isUploading = true;
                const imageRef = storageRef(storage, `profileImages/${this.userId}`); // Renamed from storageRef to imageRef
                await uploadBytes(imageRef, this.profileImage);
                this.profileImageUrl = await getDownloadURL(imageRef);
                await updateDoc(doc(db, 'users', this.userId), {
                    profileImage: this.profileImageUrl,
                });
                this.isUploading = false;
                this.profileImageState.profileImageUrl = this.profileImageUrl;
                setUserImage(this.userId, this.profileImageUrl); // Cache the image
            }
        },
        async saveUsername() {
            const usernameAvailable = await this.checkUsernameAvailability();
            if (!usernameAvailable && this.username !== this.originalUsername) {
                alert('Username is already taken');
                return;
            }
            await updateDoc(doc(db, 'users', this.userId), {
                username: this.username,
            });
            await setDoc(doc(db, 'usernames', this.username), { userId: this.userId });
            this.originalUsername = this.username;
            this.isEditingUsername = false;
        },
        cancelEdit() {
            this.username = this.originalUsername;
            this.isEditingUsername = false;
        },
        async checkUsernameAvailability() {
            try {
                const docRef = doc(db, 'usernames', this.username);
                const docSnap = await getDoc(docRef);
                return !docSnap.exists();
            } catch (error) {
                console.error('Error checking username availability', error);
                throw error;
            }
        }
    }
});
</script>


<style scoped>
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.profile-image-container {
    position: relative;
    margin-bottom: 20px;
}

.profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.camera-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
}

.camera-icon input {
    display: none;
}

.icon-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

.icon-circle svg {
    width: 16px;
    /* Set the size of the SVG */
    height: 16px;
    /* Set the size of the SVG */
    fill: white;
    /* Set the fill color of the SVG */
}

.username-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.username-container input {
    margin-right: 10px;
}

.edit-icon {
    margin-left: 10px;
    cursor: pointer;
}

button {
    margin-left: 10px;
    padding: 5px 10px;
}

.spinner {
    width: 150px;
    height: 150px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
