<template>
    <div class="side-nav">
      <div class="side-nav-header">
        <router-link to="/" class="side-nav-link">
          <img src="@/assets/home.png" alt="Home" class="icon" />
          <span>Home</span>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data() {
          return {};
      }
  };
  </script>
  
  <style scoped>
    .side-nav {
        width: 80px; /* Further reduced width */
        background-color: #f8f9fa;
        padding: 20px;
        border-right: 1px solid #ddd;
        height: 100vh;
        position: fixed;
    }
  
  .side-nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }
  
  .icon {
    width: 40px;
    height: 24px;
    margin-right: 10px;
  }
  </style>
  